<template>
  <div class="detailsGoods">
    <div style="position: fixed;top: 0;left: 0;right: 0;z-index: 10;">
      <van-nav-bar title="商品" left-arrow @click-left="Close" />
    </div>
    <!--商品图片 :autoplay="3000" -->
    <div class="goods">
      <van-swipe class="goods-swipe" :autoplay="3000" @change="swipeChange">
        <van-swipe-item v-for="(bandas,index) in drivings.picUrls" :key="index">
          <img :src="bandas" />
        </van-swipe-item>
        <template #indicator>
          <div class="custom-indicator">{{ swipeCurrent + 1 }}/{{drivings.picUrls.length}}</div>
        </template>
      </van-swipe>
      <van-cell-group>
        <!--商品描述-->
        <van-cell>
          <template slot="title">
            <span style="font-size: 17px;font-weight: 500;">
              {{drivings.name}}
            </span>
          </template>
        </van-cell>
        <!--价格 原价-->
        <div style="display: flex;padding: 0 16px 10px 16px;align-items: center;justify-content: space-between;">
          <div style="display: flex; align-items: baseline">
            <div style="color: #A80000;font-size: 14px;font-weight: 500;">￥</div>
            <div style="color: #A80000;font-size: 28px;font-weight: 500;">{{ drivings.priceDown }}</div>
            <div style="color: #A80000;font-size: 14px;font-weight: 500;">～</div>
            <div style="color: #A80000;font-size: 28px;font-weight: 500;">{{ drivings.priceUp }}</div>
            <!-- <del style="margin-left: 10px;font-size: 12px;color: #999999;">￥{{drivings.priceUp}}</del> -->
            <!-- <div style="margin-left: 10px;color: #FF5200;font-size: 12px;" @click="$router.push('/coupon')">领劵 ></div> -->
          </div>
          <div style="color: #999999;">已售{{ drivings.saleNum }}万</div>
        </div>
      </van-cell-group>
      <!-- /选择配送 -->
      <div class="delivery">
      <van-cell center is-link @click="notice">
        <template slot="title">
          <div style="display: flex;">
            <div style="font-size: 17px;
                      font-weight: 400;
                      padding-right:40px;
                      border-right:1px solid rgba(0,0,0,0.1);
                      margin-right:10px;display: flex;
                      width: 65px;
                      align-items: center;">
              选择
            </div>
            <div 
            style="font-size: 17px;font-weight: 400;color:rgba(0,0,0,0.3);"
            v-show="form.skus==''">
            请选择商品规格
            </div>
            <div>
              <span 
              style="font-size: 17px;font-weight: 400;color:rgba(0,0,0,0.3)"
              v-for="(item,index) in form.skus.specs" :key="item.id+index"
              v-show="form.skus!=''">
              {{item.specValueName}}
              </span>
              <span style="font-size: 17px;font-weight: 400;color:rgba(0,0,0,0.3)" v-show="form.skus!=''&&form.skus.specs.length==0">统一规格</span>
            </div>
          </div>
        </template>
      </van-cell>
      </div>
      <van-cell center is-link @click="notice">
        <template slot="title">
          <div style="display: flex;">
            <div style="font-size: 17px;
                      font-weight: 400;
                      padding-right:40px;
                      border-right:1px solid rgba(0,0,0,0.1);
                      margin-right:10px;display: flex;
                      width: 65px;
                      align-items: center;">
              配送
            </div>
            <span style="font-size: 17px;font-weight: 400;color:rgba(0,0,0,0.3)">{{address==''?'请选择配送地址':address.provinceName+address.cityName+address.countyName}}</span>
          </div>
        </template>
      </van-cell>
      <van-cell-group class="goods-cell-group">
        <van-cell center is-link @click="$router.push({path:'/shareShop',query: {id: drivings.shopId}})">
          <template slot="title">
            <div style="display:flex;justify-content: space-between;align-items: center;">
              <div style="display:flex;">
                <img :src="drivings.shopInfo.imgUrl?drivings.shopInfo.imgUrl:''" style="width:30px;height:30px;"/>
                <div style="font-size: 14px;font-weight: 500;color: #333333;margin-left:8px;align-items: center;display: flex;">{{ drivings.shopInfo.name }}</div>
              </div>
              <span style="color: #333333;font-size: 12px;">进入店铺</span>
            </div>
          </template>
        </van-cell>
        <!--卖家服务-->
        <van-cell center style="padding:0;">
          <van-col span="8" style="text-align: center;">
            <span style="font-size: 14px;color: #999999;margin-right:5px;">宝贝描述</span>
            <!-- <span style="font-size: 12px;color: #DA0B06;">{{drivings.score.goodsScoreAvg?drivings.score.goodsScoreAvg:'0'}}</span> -->
          </van-col>
          <van-col span="8" style="text-align: center;">
            <span style="font-size: 14px;color: #999999;margin-right:5px;">卖家服务</span>
            <!-- <span style="font-size: 12px;color: #DA0B06;">{{drivings.score.serviceScoreAvg?drivings.score.serviceScoreAvg:'0'}}</span> -->
          </van-col>
          <van-col span="8" style="text-align: center;">
            <span style="font-size: 14px;color: #999999;margin-right:5px;">物流服务</span>
            <!-- <span style="font-size: 12px;color: #DA0B06;">{{drivings.score.logisticsScoreAvg?drivings.score.logisticsScoreAvg:'0'}}</span> -->
          </van-col>
        </van-cell>
        <!-- 描述 -->
        <div class="van-multi-ellipsis--l2" style="display: flex;justify-content: space-around;padding:0 15px;">
          <div v-for="(bandas,index) in drivings.shopInfo.listGoodsSpu" :key="bandas.id+index" @click="goDetailsGoods(bandas.id)">
            <van-card>
              <template #thumb>
                <img :src="bandas.picUrls[0]?bandas.picUrls[0]:''" style="width:90px;height:90px;"/>
              </template>
              <template #price>
                <div>{{bandas.name}}</div>
              </template>
              <template #desc>
                <span style="font-size: 14px;font-weight: 500;color: #A80000;">¥</span>
                <span style="font-size: 18px;font-weight: 500;color: #A80000;">{{bandas.priceDown}}</span>
              </template>
            </van-card>
          </div>
        </div>
      </van-cell-group>

      <div style="position: fixed; bottom: 70px; right: 20px">
        <!--<div style="width: 45px;height: 45px;border-radius: 50px;line-height: 18px;text-align: center;background-color: #737073;color: #f9f9f9;">
          <div style="font-size: 20px"><van-icon name="user-circle-o" /></div>
          <div style="font-size: 3px;">客服</div>
        </div> -->

        <div style="width: 42px;height: 42px;margin-bottom: 10px;" @click="notice">
          <img src="img/detailsGoods/shopCar.png" alt="">
        </div>
        <!-- <div style="width: 42px;height: 42px;" @click="$router.push('/MyShop')">
          <img src="img/detailsGoods/my.png" alt="">
        </div> -->
      </div>
      <!--猜你喜欢-->
      <div v-html="drivings.description"></div>
      <!-- <van-goods-action fixed="true">
        <van-goods-action-icon
        icon="home-o"
        @click="$router.push({path:'/shareShop',query: {id: drivings.shopId}})">
          店铺
        </van-goods-action-icon>
        <van-goods-action-icon
        icon="cart-o"
        @click="notice">
          加入购物车
        </van-goods-action-icon>
        <van-goods-action-button
        type="warning"
        @click="notice">
          立即购买
        </van-goods-action-button>
      </van-goods-action> -->
    </div>
  </div>
</template>

<script>
// import { getObj } from "@/api/proprietary/proprietary"
import { getDetail } from "@/api/shareShop"
import { getType, addShopCar, insertMyshop } from "@/api/detailsGoods/detailsGoods"
import { Toast } from 'vant';
export default {
  data() {
    return {
      swipeCurrent: 0,
      description: null,
      drivings: {},
      typeArr:[],
      show: false,
      id: "",
      isShow: false,
      shareShow: false,
      popSureType: 0,//0选择规格1加入购物车2进入订单
      address: '',
      isFirst: false,//是否是第一页

      popup: {
        price: '',
        desc:'',
        title: '',
        thumb: ''
      },
      selectType: [],
      form:{
        skus:'',
        num:1,
        shopName:'',
        name:''
      },
      shareForm: {
        message:'',
        addPrice:'0'
      }
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === "ShippingAddress") {
        if(from.params.address)
          vm.address = from.params.address;
      }
      else{
        vm.address = '';
      }
    });
  },
  created () {
    console.log(1212)
    this.pageRefresh();
  },
  methods: {
    notice() {
      this.$toast('请下载')
    },
    //轮播图变换
    swipeChange(index) {
      this.swipeCurrent = index;
    },
    itemNumChange(){
      console.log(this.form.num);
    },
    //选择类型
    ClickOrder(index,supItem){
      if(this.selectType.indexOf(supItem.id) == -1){
        this.selectType.splice(index, 1, supItem.id);
      }
      else{
        this.selectType.splice(index, 1, '');
      }
      let isChoose = false;
      let selectItem;
      for(let item of this.drivings.skus){
        let isSupChoose = true;
        for(let index = 0;index < item.specs.length;index++){
          let specs = item.specs[index];
          if(specs.specValueId != this.selectType[index]){
            isSupChoose = false;
            break;
          }
        }
        if(isSupChoose){
          isChoose = true;
          selectItem = item;
          break;
        }
      }
      if(isChoose){
        let desc = '';
        for(let index = 0;index < selectItem.specs.length;index++){
          let specs = selectItem.specs[index];
          desc = desc.concat(specs.specValueName+' ');
        }
        this.popup = {
          price: '¥' + selectItem.salesPrice,
          desc: desc,
          title: '库存' + selectItem.stock + '个',
          thumb: selectItem.picUrl?selectItem.picUrl:''
        }
        this.form.skus = selectItem;
      }
      else{
        this.popup = {
          price: '¥' + this.drivings.priceDown + '～¥' + this.drivings.priceUp,
          desc: '请选择：商品规格',
          title: '',//库存1000个
          thumb: this.drivings.picUrls[0]?this.drivings.picUrls[0]:''
        }
        this.form.skus = '';
      }
    },
    //点击商品
    goDetailsGoods(id){
      // this.$router.push({path: '/detailCommidity',query: {id: id}});
      this.id = id
      this.getObjs();
    },
    //点击确定进入订单/添加购物车
    shoppIng(){
      if(this.form.skus == ''){
        Toast('请选择商品规格');
        return
      }
      if(this.form.skus.stock == 0){
        Toast('当前规格暂无商品');
        return
      }
      if(this.popSureType == 0){
        this.show = false;
      }
      else if(this.popSureType == 1)
        this.addShopCar();
      else
        this.$router.push({path: '/sureorder',query: {info: [JSON.stringify(this.form)], address: JSON.stringify(this.address)}});
    },
    Close() {
      if(this.isFirst){
        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //androi
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isIOS) {
          window.webkit.messageHandlers.close.postMessage("up");
        }
        if (isAndroid) {
          window.android.close();
        }
      }
      else{
        this.$router.back(-1);
        this.pageRefresh();
      }
    },
    //获取数据
    getObjs() {
      console.log(this.id);
      getDetail(this.id, this.$route.query.userId).then((res) => {
        if (res.data.code === 0) {
          console.log(res);
          this.drivings = res.data.data;
          this.popup = {
            price: '¥' + this.drivings.priceDown + '～¥' + this.drivings.priceUp,
            desc: '请选择：商品规格',
            title: '',//库存1000个
            thumb: this.drivings.picUrls[0]?this.drivings.picUrls[0]:''
          }
          // this.form.shopName = this.drivings.shopInfo.name;
          this.form.name = this.drivings.name;
          this.getType();
        }
        else{
          Toast(res.data.msg)
        }
      });
    },
    getType() {
      getType({spuId: this.id}).then((res) => {
        if (res.data.code === 0) {
          this.typeArr = res.data.data;
          this.selectType = [];
          for(let i of this.typeArr){
            console.log(i)
            this.selectType.push('')
          }
          if(this.typeArr.length == 0){
            this.form.skus = this.drivings.skus[0]
          }
        }
      });
    },
    //加入购物车
    addShopCar() {
      let desc = '';
      console.log(this.form);
      for(let index = 0;index < this.form.skus.specs.length;index++){
        let specs = this.form.skus.specs[index];
        desc = desc.concat(specs.specValueName+' ');
      }
      let form = {
        shopId: this.form.skus.shopId,
        spuId: this.form.skus.spuId,
        skuId: this.form.skus.id,
        quantity: this.form.num,
        spuName: this.drivings.name,
        addPrice: this.form.skus.salesPrice,
        specInfo: desc,
        picUrl: this.form.skus.picUrl
      }
      addShopCar(form).then((res) => {
        if (res.data.code === 0) {
          this.show = false;
          Toast('加入成功')
        }
        else{
          Toast('加入失败')
        }
      });
    },
    //页面刷新
    pageRefresh() {
      if(this.$route.query.isFirst!=null)
      {
        this.isFirst = this.$route.query.isFirst;
      }
      if(this.$route.query.id!=null)
      {
        this.id = this.$route.query.id;
        console.log(this.$route)
      }
      else{
        this.id = '1412788424246558722';//this.$route.query.id;
      }
      //获取app传递过来的session
      if(this.$route.query.WoSession!=null)
      {
        this.$store.commit('setSession', this.$route.query.WoSession)
      }
      // else{
      //   this.$store.commit('setSession', 'a6a4a59c-da25-4f1f-8416-6d5d380938ca')
      // }
      this.getObjs();
    },
    //分享赚
    shareEarn() {
      if(this.form.skus == ''){
        Toast('请选择商品规格')
      }
      else{
        this.shareShow = true;
      }
    },
    //分享赚输入格式化
    formatter(value) {
      if(value>2){
        Toast('加价幅度不能超过2%')
        return 2;
      }
      if(value<=0 || value==''){
        return 0;
      }
      return value;
    },
    //上架到我的小店
    insertMyshop() {
      let form = {
        picUrls: [this.popup.thumb],
        name: this.drivings.name,
        suggestions: this.shareForm.message,
        markup: String(Number(this.shareForm.addPrice)/100),
        priceDown: this.form.skus.salesPrice
      }
      console.log(form)
      insertMyshop(form).then((res) => {
        if (res.data.code === 0) {
          Toast('上架成功');
          this.shareForm.message = '';
          this.shareForm.addPrice = '0';
          this.shareShow = false;
        }
        else{
          Toast('上架失败');
        }
      });
    }
  },
  components: {
  }
};
</script>
<style lang="less">
.detailsGoods .delivery{
  border-bottom: 1px solid #d8d8d8;
    margin-top: 10px;
}
.detailsGoods .van-card{
  background-color: rgb(255, 255, 255);
  padding:0;
}
.detailsGoods .content {
    padding: 16px 16px 160px;
  }
.detailsGoods .van-multi-ellipsis--l2{
  .van-card__header {
    flex-direction: column;
  }
}

.detailsGoods .van-card__content {
  flex-direction: column-reverse;
  justify-content: space-around;
}
.detailsGoods .van-card__thumb {
  margin: 0;
  width: 90px;
  height: 90px;
}

.detailsGoods .choses {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background-color: #ffffff;
}
.detailsGoods .goods {
  padding: 50px 0;

  &-swipe {
    img {
      width: 100%;
      display: block;
    }
  }
  .back {
    background: url(/assets/编组8.png);
  }
  .flex-action {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .flex-cur {
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    flex-direction: column;
  }
  .flex-cur img {
    width: 50px;
    height: auto;
  }

  .shop-list-box {
    /* padding:0 10px; */
    background: white;
  }

  .shop-detail-img {
    padding-right: 10px;
  }
  .shop-detail-img img {
    width: 2rem;
    height: auto;
  }
  .shop-detail-list {
    padding-top: 10px;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.01rem solid #efeff4;
  }
  .shop-detail-intro {
    flex: 1;
    height: 100%;
  }
  &-title {
    font-size: 16px;
  }

  &-price {
    color: #f44;
  }

  &-express {
    color: #999;
    font-size: 12px;
    padding: 5px 15px;
  }

  &-cell-group {
    margin: 15px 0;
  }

  &-tag {
    margin-left: 5px;
  }
}
.detailsGoods .info {
  width: 100%;
  display: block;
  overflow-wrap: break-word;
  text-align: center;
  align-self: center;
  margin-top: 20px;
}
.detailsGoods .info1 {
  width: 100%;
  display: block;
  overflow-wrap: break-word;
  text-align: center;
  align-self: center;
  background-color: #f70035;
}
.detailsGoods .info2 {
  width: 100%;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-family: PingFangSC-Regular;
  line-height: 22px;
  text-align: center;
  align-self: center;
  margin-bottom: 20px;
  padding: 15px;
}

.detailsGoods .icon2 {
  width: 23px;
  height: 23px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.detailsGoods .info5 {
  width: 100%;
  display: block;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 11px;
  letter-spacing: -0.30000001192092896px;
  font-family: PingFangSC-Regular;
  line-height: 16px;
  text-align: center;
  align-self: center;
  margin-top: 11px;
}
.detailsGoods .info6 {
  display: block;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: center;
  align-self: center;
  padding: 10px;
}
.detailsGoods .word14 {
  width: 100%;
  display: block;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  letter-spacing: -1.2000000476837158px;
  font-family: PingFangSC-Regular;
  line-height: 22px;
  text-align: center;
  align-self: center;
  margin-top: 9px;
}

.detailsGoods .outer6 {
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng2e0399f157d37a6b197ee7e58871592ec12ce1e296f4a69df189dcf12ed453b4)
    100% no-repeat;
  align-self: center;
  margin-top: 10px;
  justify-content: center;
  align-items: flex-end;
  padding-right: 10px;
  position: flex;
  height: 80px;
}

.detailsGoods .custom-indicator {
  position: absolute;
  right: 16px;
  bottom: 10px;
  font-size: 11px;
  background: rgba(0, 0, 0, 0.3);
  height: 22px;
  width: 34px;
  border-radius: 11px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.detailsGoods .noSelect {
  background: #EEEEEE;
  border-radius: 5px;
  margin-right:15px;
  font-size: 12px;
  color: #999999;
}

.detailsGoods .select {
  background-image: linear-gradient(to right,#FF7200, #FF3C00);
  border-radius: 5px;
  margin-right:15px;
  font-size: 12px;
  color: #FFFFFF;
}

.detailsGoods .popCard{
  position: sticky;
  top:23px;
  display: flex;
  justify-content: space-between;
  z-index: 100;
  background: #FFFFFF;
  padding-left:10px;
}

.detailsGoods .van-cell::after{
  border:none;
}
</style>
